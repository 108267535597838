import React from "react";
import styled, { keyframes } from "styled-components";

const rotator = keyframes`
     0% {
       transform: rotate(0deg);
     }
     100% {
       transform: rotate(270deg);
     }
   `;

const Container = styled.svg`
  --offset: 250;
  --duration: 1500ms;
  transform-origin: center;
  width: 1rem;
  animation: ${rotator} var(--duration) linear infinite;
`;

const dash = keyframes`
    0% {
      stroke-dashoffset: var(--offset);
    }
    50% {
      stroke-dashoffset: var(--offset) / 4;
      transform: rotate(135deg);
    }
    100% {
      stroke-dashoffset: var(--offset);
      transform: rotate(450deg);
    }
  `;

const Circle = styled.circle`
  stroke-dasharray: var(--offset);
  stroke-dashoffset: 0;
  transform-origin: center;
  animation: ${dash} var(--duration) ease-in-out infinite;
  stroke: currentColor;
`;

export default function Spinner() {
  return (
    <Container viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
      <Circle
        fill="none"
        strokeWidth={10}
        strokeLinecap="round"
        cx="50"
        cy="50"
        r="40"
      ></Circle>
    </Container>
  );
}
