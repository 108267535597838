import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import Spinner from "./Spinner";

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-column-gap: 40px;
  margin: 70px 0;

  * {
    box-sizing: border-box;
  }

  @media (max-width: 768px) {
    margin: 40px 0;
  }
`;

const Intro = styled.div`
  grid-column: 1 / 7;
  p {
    margin-top: 0;
  }
  @media (min-width: 768px) {
    grid-column: 2 / 6;
  }
  @media (min-width: 1024px) {
    grid-column: 2 / 5;
  }
`;

const Controls = styled.section`
  display: contents;
  @media (min-width: 768px) {
    display: flex;
    gap: 40px;
    align-items: flex-start;
    flex-flow: column;
    grid-column: 1 / 2;
    grid-row: 2 / 3;
  }
  p {
    margin-top: 0;
    margin-bottom: 0.5em;
  }
`;

const GlobalControls = styled.div`
  grid-row: 4;
  grid-column: span 4;
  margin-bottom: 1em;
  @media (min-width: 768px) {
    margin-bottom: 0;
    display: contents;
  }
`;

const ColorsList = styled.div`
  grid-column: 1 / 4;
  @media (min-width: 768px) {
    flex-basis: auto;
  }
  ul {
    display: flex;
    gap: 12px;
    flex-wrap: wrap;
  }
`;

const ColorButton = styled.button`
  background: var(--background);
  font-size: 0;
  width: 2.75rem;
  height: 2.75rem;
  transition: 100ms;
  aspect-ratio: 1 / 1;
  appearance: none;
  border-radius: 1000px;

  box-shadow: ${props =>
    props.isActive ? "inset 0 0 0 4px  black" : "inset 0 0 0 0 transparent"};
  &:hover,
  &:focus {
    outline: inherit;
  }
`;

const WidthsList = styled.div`
  grid-column: 4 / 7;

  @media (min-width: 768px) {
    flex-basis: auto;
  }
  ul {
    display: flex;
    gap: 10px;
    flex-flow: column;
  }
`;

const WidthButton = styled.button`
  width: 6.5rem;
  height: 2.5rem;
  display: flex;
  appearance: none;
  font-size: 0;
  padding: 0.75rem 0;
  border-radius: 1000px;
  transition: 100ms;
  align-items: center;

  svg {
    display: block;
    overflow: visible;
    width: 100%;
  }
  path {
    stroke: ${props => (props.isActive ? "black" : "white")};
    stroke-linecap: round;
  }
  &:hover,
  &:focus {
    outline: inherit;
  }
  @media (min-width: 768px) {
    width: 7rem;
    height: 2rem;
  }
`;

const CanvasContainer = styled.section`
  grid-column: 1 / 7;
  margin-bottom: 1em;
  margin-top: 1em;
  @media (min-width: 768px) {
    grid-column: 2 / 6;
  }
  @media (min-width: 1024px) {
    grid-column: 2 / 5;
  }
  img {
    display: none;
  }

  canvas {
    touch-action: none;
    display: block;
    width: 100%;
    background: white;
    aspect-ratio: 1 / 1;
    border: Max(0.4vw, 4px) solid white;
  }
`;

const FormContainer = styled.section`
  grid-column: 1 / 7;
  @media (min-width: 768px) {
    grid-column: 2 / 5;
  }
`;

const FormRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  gap: 0.5em;
`;
const FormField = styled.div`
  display: flex;
  gap: 0.5em;
  flex-grow: 1;
  opacity: ${props => {
    return props.state === "loading" ? ".5" : "1";
  }};
  label {
    white-space: nowrap;
  }
  input {
    background: transparent;
    border: 0;
    appearance: none;
    width: 100%;
    border-bottom: 1px solid black;
    &:focus-visible {
      outline: 0;
    }
  }
`;

const PrimaryButton = styled.button`
  background-color: white;
  border: 0;
  display: flex;
  gap: 5px;
  border-radius: 3px;
  text-align: center;
  cursor: pointer;
  padding: 4px 10px;
  transition: 250ms;
  font-weight: 200;
  font-size: 14px;
  @media (min-width: 768px) {
    font-size: 18px;
    line-height: 23px;
  }
  &[disabled] {
    color: gray;
    circle {
      stroke: black;
    }
    &:hover,
    &:focus {
      text-decoration: none;
      cursor: default;
      background-color: white;
    }
  }
  &:hover,
  &:focus {
    background: black;
    color: white;
  }
`;

const ErrorsContainer = styled.div`
  color: #d71e05;
  ul {
    margin-left: 1em;
  }
  li {
    list-style: disc;
  }
`;

const DebugContainer = styled.aside`
  color: red;
  font-family: monospace;
  font-size: 15px;
  line-height: 1.1;
  grid-column: 5/8;
  grid-column: 1 / 7;
  grid-row: 5;
  display: none;
`;

export default function DrawCanvas({
  colors,
  intro,
  disclaimer,
  success,
  backdrop,
}) {
  if (!colors) {
    colors = ["#f13005", "#0dad08", "#0d0de7"];
  }

  const widths = [5, 25];
  const [width, setWidth] = useState(0);
  const [color, setColor] = useState(0);
  const [lastPoint, setLastPoint] = useState([]);
  const [isDrawing, setIsDrawing] = useState(false);
  const [imageData, setImageData] = useState("");
  const [formData, setFormData] = useState({ name: "" });
  const [formState, setFormState] = useState("idle");
  const [isTouch, setIsTouch] = useState(false);
  const [backdropImage, setBackdropImage] = useState(null);

  const [formErrors, setFormErrors] = useState([]);

  const [c, setC] = useState(null);
  const canvasRef = useRef(null);
  const [canvasScale, setCanvasScale] = useState(1);

  const updatePen = function() {
    c.lineCap = "round";
    c.strokeStyle = colors[color];
    c.lineWidth = widths[width] * canvasScale;
  };

  const handleMouseMove = function(e) {
    if (isTouch) return;
    const { x, y } = c.canvas.getBoundingClientRect();
    const mousePosition = [
      Math.round((e.clientX - x) * canvasScale),
      Math.round((e.clientY - y) * canvasScale),
    ];
    if (isDrawing) {
      c.beginPath();
      c.moveTo(lastPoint[0], lastPoint[1]);
      c.lineTo(mousePosition[0], mousePosition[1]);
      c.stroke();
    }
    setLastPoint(mousePosition);
  };

  const handleTouchStart = function(e) {
    e.preventDefault();
    setIsDrawing(true);
    setIsTouch(true);
    updatePen();
    const touch = e.changedTouches[0];

    const { x, y } = c.canvas.getBoundingClientRect();
    const mousePosition = [
      Math.round((touch.clientX - x) * canvasScale),
      Math.round((touch.clientY - y) * canvasScale),
    ];
    setLastPoint(mousePosition);
  };

  const handleTouchEnd = function(e) {
    setIsDrawing(false);
  };

  const handleTouchMove = function(e) {
    e.preventDefault();
    const touch = e.touches[0];
    const { x, y } = c.canvas.getBoundingClientRect();
    const mousePosition = [
      Math.round((touch.clientX - x) * canvasScale),
      Math.round((touch.clientY - y) * canvasScale),
    ];
    if (isDrawing) {
      c.beginPath();
      c.moveTo(lastPoint[0], lastPoint[1]);
      c.lineTo(mousePosition[0], mousePosition[1]);
      c.stroke();
    }
    setLastPoint(mousePosition);
  };

  const handleMouseDown = function(e) {
    setIsTouch(false);
    setIsDrawing(true);
    updatePen();
    const { x, y } = c.canvas.getBoundingClientRect();
    const mousePosition = [
      Math.round((e.clientX - x) * canvasScale),
      Math.round((e.clientY - y) * canvasScale),
    ];
    setLastPoint(mousePosition);
  };
  const handleMouseUp = function() {
    setIsDrawing(false);
  };

  const handleClear = function() {
    c.clearRect(0, 0, c.canvas.width, c.canvas.height);
    drawBackdrop();
  };

  const drawBackdrop = function() {
    c.drawImage(backdropImage, 0, 0, c.canvas.width, c.canvas.height);
  };

  const handleFormChange = function(e) {
    const field = e.target.getAttribute("id");
    const value = e.target.value;
    console.log(field);
    setFormData(prev => {
      const newData = { ...prev };
      newData[field] = value;
      return newData;
    });
  };

  async function handleSubmit(e) {
    e.preventDefault();
    const imageData = c.canvas.toDataURL("image/png").split(";base64,")[1];
    setImageData(imageData);
    setFormState("loading");
    const res = await fetch("/.netlify/functions/handleDrawingSubmission", {
      method: "POST",
      body: JSON.stringify({
        name: formData.name,
        imageData: imageData,
      }),
    });
    if (res.status === 200) {
      setFormErrors([]);
      setFormState("success");
    } else {
      const body = await res.json();
      setFormErrors(body.errors);
      setFormState("error");
    }
  }

  useEffect(() => {
    const context = canvasRef.current.getContext("2d");
    const { width } = canvasRef.current.getBoundingClientRect();
    context.canvas.width = 1500;
    context.canvas.height = 1500;
    setCanvasScale(1500 / width);
    setC(context);
  }, [canvasRef]);

  useEffect(() => {
    if (c) {
      const img = new Image();
      img.crossOrigin = "anonymous";
      img.src = backdrop.url;
      img.onload = () => {
        setBackdropImage(img);
      };
    }
  }, [c]);

  useEffect(() => {
    if (backdropImage) {
      drawBackdrop();
    }
  }, [backdropImage]);

  return (
    <Grid>
      <Intro>
        <div
          dangerouslySetInnerHTML={{
            __html: intro.html,
          }}
        />
      </Intro>
      <Controls>
        <ColorsList>
          <p>Pick a colour</p>
          <ul>
            {colors.map((c, i) => {
              return (
                <li key={`color.${i}`}>
                  <ColorButton
                    isActive={i === color}
                    style={{ "--background": c }}
                    onClick={() => {
                      setColor(i);
                    }}
                  >
                    {c}
                  </ColorButton>
                </li>
              );
            })}
          </ul>
        </ColorsList>
        <WidthsList>
          <p>Pick a thickness</p>
          <ul>
            {widths.map((w, i) => {
              return (
                <li key={`width.${w}`}>
                  <WidthButton
                    isActive={i === width}
                    style={{}}
                    onClick={() => {
                      setWidth(i);
                    }}
                  >
                    <svg
                      viewBox={`${w * -0.25} 0 ${32 + w * 0.5} 5`}
                      fill="none"
                      preserveAspectRatio="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M0.448965 3.73996C13.4056 -4.2477 20.1722 8.81848 31.551 1.68552"
                        strokeWidth={w * 0.5}
                      />
                    </svg>

                    {w}
                  </WidthButton>
                </li>
              );
            })}
          </ul>
        </WidthsList>
        <GlobalControls>
          <PrimaryButton onClick={handleClear}>Clear</PrimaryButton>
        </GlobalControls>
      </Controls>
      <CanvasContainer>
        <canvas
          ref={canvasRef}
          onMouseMove={handleMouseMove}
          onMouseDown={handleMouseDown}
          onMouseUp={handleMouseUp}
          onTouchStart={handleTouchStart}
          onTouchEnd={handleTouchEnd}
          onTouchMove={handleTouchMove}
          onMouseLeave={() => {
            setIsDrawing(false);
          }}
        ></canvas>
      </CanvasContainer>
      <FormContainer>
        {formState === "success" ? (
          <>
            <div
              dangerouslySetInnerHTML={{
                __html: success.html,
              }}
            />
            <p>
              <PrimaryButton
                onClick={() => {
                  setFormState("idle");
                }}
              >
                Submit another drawing
              </PrimaryButton>
            </p>
          </>
        ) : (
          <>
            <form onSubmit={handleSubmit}>
              <FormRow>
                <FormField state={formState}>
                  <label htmlFor="name">Your Name * </label>
                  <input
                    disabled={formState === "loading"}
                    type="text"
                    id="name"
                    required
                    onChange={handleFormChange}
                    value={formData["name"]}
                  />
                </FormField>
                <PrimaryButton type="submit" disabled={formState === "loading"}>
                  Submit
                  {formState === "loading" && <Spinner />}
                </PrimaryButton>
              </FormRow>
            </form>
            {formErrors.length > 0 && (
              <ErrorsContainer>
                <p>
                  Your drawing could not be submitted due to the following
                  errors:
                </p>
                <ul>
                  {formErrors.map(err => {
                    return <li>{err.message}</li>;
                  })}
                </ul>
              </ErrorsContainer>
            )}
          </>
        )}
        <div
          dangerouslySetInnerHTML={{
            __html: disclaimer.html,
          }}
        />
      </FormContainer>

      <DebugContainer>
        <p>
          {JSON.stringify(colors)}
          <br />
          Current color: {color} ({colors[color]})
          <br />
          Current width: {width} ({widths[width]})
          <br />
          Point: {JSON.stringify(lastPoint)}
          <br />
          Is drawing?: {JSON.stringify(isDrawing)}
          <br />
          Image data: {imageData.length}
          <br />
          Form state: {formState}
          <br />
          Form data: {JSON.stringify(formData)}
        </p>
      </DebugContainer>
    </Grid>
  );
}
