import React, { useContext, useEffect } from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import { Helmet } from "react-helmet";

import { withPreview } from "gatsby-source-prismic";
import { PageType } from "../components/context/page-type";
import DrawCanvas from "../components/global/DrawCanvas";

const Page = styled.div`
  margin: 40px 0 0 0;

  @media (max-width: 900px) {
    margin: 55px 0 0 0;
  }
`;

const Draw = ({ data }) => {
  const [pageType, setPageType] = useContext(PageType);

  useEffect(() => {
    setPageType(`draw`);
  }, []);

  return (
    <>
      <Helmet
        title={`Draw | Three Rivers | Bexley`}
        meta={[
          {
            name: "title",
            content: `Draw | Three Rivers | Bexley`,
          },
          {
            property: "og:title",
            content: `Draw | Three Rivers | Bexley`,
          },
          {
            property: "twitter:title",
            content: `Draw | Three Rivers | Bexley`,
          },
        ]}
      />
      <Page>
        <DrawCanvas
          backdrop={data.prismicDraw.data.backdrop}
          colors={data.prismicDraw.data.colours.map(el => el.colour)}
          intro={data.prismicDraw.data.intro}
          success={data.prismicDraw.data.success_text}
          disclaimer={data.prismicDraw.data.disclaimer}
        />
      </Page>
    </>
  );
};

export const query = graphql`
  query {
    prismicDraw {
      _previewable
      data {
        title {
          text
          html
        }
        intro {
          text
          html
        }
        success_text {
          text
          html
        }
        disclaimer {
          text
          html
        }
        backdrop {
          url
        }
        colours {
          colour
        }
      }
    }
  }
`;

export default withPreview(Draw);
